export default class PayrollReportFilter {

    public companyId: number | null = null;

    public category: string | null = null;

    public period: string | null = null;

}

export const FEDERAL_CATEGORY = "FEDERAL", STATE_CATEGORY = "STATE";